
export default {
  name: 'toast',
  inheritAttrs: true,
  props: {
    isPopup: {
      type: Boolean,
      required: true,
    },
  },
};
