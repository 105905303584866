<template>
  <div class="popup-toast" v-show="isPopup">
    <div
      class="toast"
      :class="{
        'toast--visible': isPopup,
      }"
      v-bind="$attrs"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'toast',
  inheritAttrs: true,
  props: {
    isPopup: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.toast {
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  opacity: 0;
  bottom: 50%;

  transition: all 0.2s;
  opacity: 0;

  width: 50%;
  height: auto;
  min-height: 80px;

  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  z-index: 9;
  color: white;
  font-weight: 500;
  font-size: 17px;

  border-radius: 10px;
  box-shadow: rgba(92, 92, 92, 0.24) 0px 3px 8px;
  word-break: keep-all;
}

.toast--visible {
  opacity: 0.8;
  background-color: #3b3b3b;
  word-break: keep-all;
}

@media (max-width: 768px) {
  .toast {
    position: fixed;
    margin: 0 auto;
    left: 0;
    right: 0;
    opacity: 0;
    bottom: 50%;

    transition: all 0.2s;
    opacity: 0;

    width: 90%;
    height: auto;
    min-height: 80px;

    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    z-index: 999;
    color: white;
    font-weight: 500;
    font-size: 17px;

    border-radius: 10px;
    box-shadow: rgba(92, 92, 92, 0.24) 0px 3px 8px;
    word-break: keep-all;
  }

  .toast--visible {
    word-break: keep-all;
    opacity: 0.8;
    background-color: #3b3b3b;
  }
}
</style>
